import { enableProdMode } from '@angular/core';
import * as Sentry from '@sentry/angular-ivy';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import './polyfills';
import pkg from '../package.json';
import { AppModule } from './app';
import defaultConfig from './config.json';
import { environment } from './environments/environment';

const isLocalhost = window.location.hostname === 'localhost';

Sentry.init({
  release: pkg.version,
  dsn: 'https://e014f9c742eaad122527aa5278b9a942@o458744.ingest.sentry.io/4506316562694144',
  enabled: !isLocalhost,
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        'localhost',
        'admin.airwebpass.com',
        'airwebpass.com',
        'na.admin.airwebpass.com',
        'bo.ticket.run.airweb.dev',
      ],
      routingInstrumentation: Sentry.routingInstrumentation,
      traceFetch: false,
    }),
  ],

  // Performance Monitoring
  tracesSampleRate: 0.5, // Capture 100% of the transactions
  // Session Replay
  // replaysSessionSampleRate: 0.5, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  // replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

if (environment.production) {
  enableProdMode();
}

if (environment.config.clarityCode !== 'fake-code') {
  // @ts-ignore
  tarteaucitron.user.clarity = environment.config.clarityCode;
  // @ts-ignore
  (tarteaucitron.job = tarteaucitron.job || []).push('clarity');
}

async function main() {
  try {
    window['__CONFIG__'] = {
      features: {
        globalSearch: true,
        dashboard: true,
        customerSearch: true,
        orderSearch: true,
        globalSearchPreview: true,
      },
    };

    if (environment.production) {
      try {
        const url = new URL(environment.config.configURL);
        url.searchParams.set('cache', Date.now().toString());
        window['__CONFIG__'] = await fetch(url.toString()).then((response) => response.json());
      } catch (e) {
        window['__CONFIG__'] = defaultConfig;
        console.log('failed to fetch global config', { e });
      }
    }
  } finally {
    return platformBrowserDynamic().bootstrapModule(AppModule);
  }
}

main();
