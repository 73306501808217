import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { PermissionsService } from '../services/permissions.service';

@Injectable()
export class RolesResolver implements Resolve<any[]> {
  constructor(private permissions: PermissionsService) {}

  resolve(): Observable<any[]> {
    return this.permissions.getRoles();
  }
}
