import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';

const AVAILABLE_COLORS = [
  '#162636',
  '#222222',
  '#e5e5e5',
  '#0b6484',
  '#46403b',
  '#413256',
  '#b43d38',
  '#69a8bb',
];
const LIGHT = '#ffffff';
const DARK = '#000000';
const USER_PREFERENCES = 'userPreferences';

@Injectable()
export class ColorService {
  constructor(private auth: AuthService) {
    const userPreferencesJson = localStorage.getItem(USER_PREFERENCES);
    const userPreferences = JSON.parse(userPreferencesJson);

    if (userPreferences?.[this.user_id]) {
      this._sidebarColor = userPreferences[this.user_id].sidebarColor;
    }
  }

  public availableColors = AVAILABLE_COLORS;

  private _sidebarColor: string = AVAILABLE_COLORS[0];

  private user_id = this.auth.user_id;

  public get sidebarColor() {
    const text = this.isColorLight(this._sidebarColor) ? DARK : LIGHT;
    const selected = this.shadeColor(this._sidebarColor, 20);
    const hover = this.shadeColor(this._sidebarColor, 30);
    const dropdown = this.shadeColor(this._sidebarColor, 10);

    return { primary: this._sidebarColor, selected, hover, dropdown, text };
  }

  public setSidebarColor(color: string) {
    this._sidebarColor = color;

    const userPreferencesJson = localStorage.getItem(USER_PREFERENCES);
    const userPreferences = JSON.parse(userPreferencesJson);

    if (!userPreferences) {
      const updatedUserPreferences = { [this.user_id]: { sidebarColor: color } };

      return localStorage.setItem(USER_PREFERENCES, JSON.stringify(updatedUserPreferences));
    }

    const selectedUserPreferences = userPreferences[this.user_id];
    const updatedUserPreferences = {
      ...userPreferences,
      [this.user_id]: { ...selectedUserPreferences, sidebarColor: color },
    };

    localStorage.setItem(USER_PREFERENCES, JSON.stringify(updatedUserPreferences));
  }
  //https://stackoverflow.com/questions/12043187/how-to-check-if-hex-color-is-too-black
  private isColorLight(color: string) {
    const c = color.substring(1); // strip #
    const rgb = parseInt(c, 16); // convert rrggbb to decimal

    const r = (rgb >> 16) & 0xff; // extract red
    const g = (rgb >> 8) & 0xff; // extract green
    const b = (rgb >> 0) & 0xff; // extract blue

    const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709

    //https://www.w3.org/TR/WCAG21/
    const contrast = (0.8 * (luma / 255 + 0.05)) / 0.05;

    //WCAG20 1.4.6
    return contrast > 7;
  }

  //https://stackoverflow.com/questions/5560248/programmatically-lighten-or-darken-a-hex-color-or-rgb-and-blend-colors
  private shadeColor(color: string, percent: number) {
    let R = parseInt(color.substring(1, 3), 16);
    let G = parseInt(color.substring(3, 5), 16);
    let B = parseInt(color.substring(5, 7), 16);

    const ratio = (percent * 255) / 100;

    R = Math.floor(this.isColorLight(color) ? R - ratio : R + ratio);
    G = Math.floor(this.isColorLight(color) ? G - ratio : G + ratio);
    B = Math.floor(this.isColorLight(color) ? B - ratio : B + ratio);

    R = R < 255 ? R : 255;
    G = G < 255 ? G : 255;
    B = B < 255 ? B : 255;

    R = R > 0 ? R : 0;
    G = G > 0 ? G : 0;
    B = B > 0 ? B : 0;

    const RR = R.toString(16).length == 1 ? '0' + R.toString(16) : R.toString(16);
    const GG = G.toString(16).length == 1 ? '0' + G.toString(16) : G.toString(16);
    const BB = B.toString(16).length == 1 ? '0' + B.toString(16) : B.toString(16);

    return '#' + RR + GG + BB;
  }
}
