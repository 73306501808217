import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

import { environment as env } from '@env/environment';
import { GenericResponse } from '../models/generic-response';

export interface ILanguage {
  id: string;
  locale: string;
  name: string;
  flag: string;
}

@Injectable()
export class TranslationService {
  public translations: ILanguage[] = [];
  public currentLang: string = this.translateService.getDefaultLang();

  constructor(private http: HttpClient, private translateService: TranslateService) {}

  public getTranslations() {
    this.translateService.onLangChange.subscribe((currentLanguage) => {
      this.currentLang = currentLanguage.lang;
    });

    return this.http
      .get<GenericResponse<ILanguage[]>>(env.config.feedRoot + `Translations/getLanguages`, {
        params: { lang: this.currentLang },
      })
      .pipe(
        map(({ response }) => {
          this.translations = response;
          return response;
        })
      );
  }
}
