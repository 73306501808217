import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@app/modules/shared/services/auth.service';
import { FilterService } from '@app/modules/shared/services/filter.service';
import { environment as env } from '@env/environment';
import { firstValueFrom, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { GenericResponse } from '../../shared/models/generic-response';
import { Document, Submission } from '../models/submission';

export interface $$Submission {
  id: number;
  date: string;
  product: string;
  status: string;
  supports: string[];
  docs: Doc[];
  user: User;
}

interface User {
  customer_id: number;
  fullname: string;
}

interface Doc {
  document_id: number;
  document_type_name: string;
  document_name: string;
  status: string;
  file_deleted_at: string;
  created_at: string;
  expires_at?: string;
  examined_at?: string;
  deleted_at?: string;
  comment?: string;
  url: string;
  file_id: string;
  file_label: string;
  type_id: number;
}

@Injectable({
  providedIn: 'root',
})
export class SubmissionService {
  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private filters: FilterService,
    private router: Router
  ) {}

  public getUnsubmitted(nid: any = null): Observable<number> {
    return of([null]).pipe(
      switchMap(() =>
        this.http.get<any>(env.config.feedRoot + `Submission/getUnsubmitted.json`, {
          params: { network_id: nid },
        })
      ),

      map(({ response: r }) => {
        if (r.errorMessage) return {} as any;
        return r.submissions as number;
      })
    );
  }

  public getSubmissions(): Observable<Submission[]> {
    return of([null]).pipe(
      switchMap(() =>
        this.http.get<any>(env.config.feedRoot + `Submission/list.json`, {
          params: this.filters.filtersWithID,
        })
      ),

      map(({ response: r }) => {
        return r.submissions as Submission[];
      })
    );
  }

  public $$getUserSubmissions(customerId: string): Promise<Submission[]> {
    const http$ = this.http
      .get<GenericResponse<{ submissions: Submission[] }>>(
        env.config.feedRoot + `Submission/getUserSubmissions.json`,
        {
          params: { user_id: customerId },
        }
      )
      .pipe(
        map(({ response }) => {
          return response.submissions;
        })
      );

    return firstValueFrom(http$);
  }

  public getUserSubmissions(user_id: any): Observable<Submission[]> {
    return of([null]).pipe(
      switchMap(() =>
        this.http.get<any>(env.config.feedRoot + `Submission/getUserSubmissions.json`, {
          params: { user_id },
        })
      ),

      map(({ response: r }) => {
        return r.submissions as Submission[];
      })
    );
  }

  public getSubmissionDetail(id: number) {
    return this.http
      .get<GenericResponse<{ submission: $$Submission }>>(
        env.config.feedRoot + `Submission/details.json`,
        { params: { id, antiCache: Date.now() } }
      )
      .pipe(
        map(({ response }) => {
          const documents = response.submission.docs as Doc[];
          /**
           * Essentially group every document by types:
           * ```ts
           * {
           *   '1345': [{ ... }],
           *   '1346': [{ ... }]
           * }
           * ```
           */
          const documentsGroupedByType = documents.reduce<Record<string, Doc[]>>(
            (acc: { [x: string]: any[] }, doc: { type_id: string | number }) => {
              if (!acc[doc.type_id]) {
                acc[doc.type_id] = [];
              }

              acc[doc.type_id].push(doc);

              return acc;
            },
            {}
          );

          /**
           * Then picking only the latest one (based on their ID)
           */
          const filteredDocuments = Object.values(documentsGroupedByType).reduce<Doc[]>(
            (acc, docs) => {
              const [latestDocumentForThisType] = docs.sort(
                (a, b) => b.document_id - +a.document_id
              );
              acc.push(latestDocumentForThisType);

              return acc;
            },
            []
          );

          return { ...response.submission, docs: filteredDocuments };
        })
      );
  }

  public getDocumentDetail(document_id: number): Observable<string> {
    return this.http
      .get<GenericResponse<{ url: string }>>(
        env.config.feedRoot + `/Submission/documentFileDownloadUrl.json`,
        {
          params: { documentId: document_id },
        }
      )
      .pipe(
        map(({ response }) => {
          return response.url;
        })
      );
  }

  public updateSubmission(
    id: string | number,
    submission_statut: string,
    documents: any[]
  ): Observable<{ id: number; documents: Array<Document>; submission_status: string }> {
    return this.http
      .post<GenericResponse<{ id: number; documents: Array<Document>; submission_status: string }>>(
        env.config.feedRoot + `Submission/updateSubmission.json`,
        {
          id,
          submission_statut,
          documents,
        }
      )
      .pipe(
        map(({ response }) => {
          return response;
        })
      );
  }

  public postDocumentComment(document_id: string, comment: string) {
    return this.http
      .post<GenericResponse<{ id: number; comment: string }>>(
        env.config.feedRoot + `Submission/postDocumentComment.json`,
        { document_id, comment }
      )
      .pipe(
        map(({ response }) => {
          return response;
        })
      );
  }

  public deleteDocument(documentId: string) {
    return firstValueFrom(
      this.http
        .delete<any>(env.config.feedRoot + `Submission/deleteDocument.json`, {
          params: { documentId },
        })
        .pipe(map(({ response }) => response))
    );
  }

  public deleteDocumentFile(documentId: string) {
    return firstValueFrom(
      this.http
        .delete<GenericResponse<{ success: boolean }>>(
          env.config.feedRoot + `Submission/deleteDocumentFile`,
          {
            params: { documentId },
          }
        )
        .pipe(map(({ response }) => response))
    );
  }

  public updateDocument(body: {
    documentTypeId: string;
    userId: string;
    file: File;
    submissionId?: string;
  }) {
    const formData = new FormData();
    formData.append('documentTypeId', body.documentTypeId);
    formData.append('userId', body.userId);
    formData.append('file', body.file);

    if (body.submissionId) {
      formData.append('submissionId', body.submissionId);
    }

    return firstValueFrom(
      this.http
        .post<GenericResponse>(env.config.feedRoot + `Submission/updateDocument`, formData)
        .pipe(map(({ response }) => response))
    );
  }
}
