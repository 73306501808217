<div
  class="modal"
  role="alertdialog"
  aria-labelledby="modal-context"
  tabindex="0"
  [hidden]="!opened"
>
  <div class="ctn">
    <h2 *ngIf="popup?.title">{{ popup?.title }}</h2>
    <p id="modal-context">{{ popup?.content }}</p>
    <input
      class="input_time border border-primary"
      *ngIf="popup?.input"
      [type]="popup.input.type"
      [(ngModel)]="currValue"
      [placeholder]="popup.input.placeholder"
      [src]="popup.input.src"
      name="date"
    />
    <div class="btn-ctn" #answer>
      <button *ngFor="let opt of popup?.options" [value]="opt.value" class="text-primary">
        {{ opt.label }}
      </button>
    </div>
  </div>
</div>
