import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class CustomersFiltersService extends BehaviorSubject<ICustomersFilters> {
  constructor() {
    super({
      attribute: null,
      attribute_search: null,
      virtual_provider: null,
    });
  }

  /**
   * Getter retrieving value object without falsy keys
   */
  getCleanValue() {
    return Object.entries(this.value).reduce((a, [k, v]) => (v ? { ...a, [k]: v } : a), {});
  }
}

export interface ICustomersFilters {
  attribute: string;
  attribute_search: string;
  virtual_provider: string;
}
