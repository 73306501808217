import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'tu-breadcrumb',
  templateUrl: './breadcrumb.component.html',
})
export class BreadcrumbComponent {
  // Will be used to create the breadcrumb
  public breadcrumbs: Array<Object>;

  constructor(private router: Router, private route: ActivatedRoute) {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      this.breadcrumbs = [];

      let currentRoute = this.route.root;
      let url = '';
      let routeStep = 0;

      do {
        const childrenRoutes = currentRoute.children;
        currentRoute = null;

        for (const childRoute of childrenRoutes) {
          if (childRoute.outlet !== 'primary') continue;

          currentRoute = childRoute;

          if (!childRoute.snapshot.data['title']) continue;

          routeStep++;

          // Compute breadcrumb step URL
          const urlExtension = '/' + this.router.url.split('/')[routeStep];
          url = !url ? urlExtension : url + urlExtension;

          // Add breadscrumb step data, i.e. step title and URL
          this.breadcrumbs.push({
            data: childRoute.snapshot.data,
            url,
          });
        }
      } while (currentRoute);
    });
  }
}
