import { Injectable } from '@angular/core';
import { DateFormatPipe } from 'ngx-moment';
import { QueryParamsService } from './queryParams.service';
import { NavigationStart, Router } from '@angular/router';
import { getDefaultFrom, getDefaultTo } from '../utils/filter';

@Injectable()
export class FilterService {
  private _filters: {
    network: string;
    networkName: string;
    from: Date;
    to: Date;
    query: string;
    onlyActive: boolean;
  };

  public set filters(updatedFilters) {
    let from = null;
    let to = null;

    if (updatedFilters.from) {
      from = new Date(updatedFilters.from);
      from.setHours(0);
      from.setMinutes(0);
      from.setSeconds(0);
    }
    if (updatedFilters.to) {
      to = new Date(updatedFilters.to);
      to.setHours(23);
      to.setMinutes(59);
      to.setSeconds(59);
    }

    this._filters = { ...updatedFilters, from, to };
    // TODO : remove legacy filters
    this.query = updatedFilters.query;
    this.networkName = updatedFilters.networkName;

    this.queryParamsService.globalFilterParams = this.filters;
  }

  public get filters() {
    let from: string = null;
    let to: string = null;

    if (this._filters.from) from = this._filters.from.toISOString();
    if (this._filters.to) to = this._filters.to.toISOString();

    return { ...this._filters, from, to };
  }

  public get filtersWithID() {
    const filters = this.filters;

    return {
      from: filters.from,
      to: filters.to,
      query: filters.query,
      network_id: filters.network,
    };
  }

  public set network(value: string) {
    this._filters.network = value;

    this.queryParamsService.globalFilterParams = this.filters;
  }

  public get network() {
    return this._filters.network;
  }
  public networkName: string = null;

  public query: string = null;

  set from(date: Date) {
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);

    this._filters.from = date;
    this.queryParamsService.globalFilterParams = this.filters;
  }
  get from(): Date {
    return this._filters.from;
  }

  set to(date: Date) {
    date.setHours(23);
    date.setMinutes(59);
    date.setSeconds(59);

    this._filters.to = date;
    this.queryParamsService.globalFilterParams = this.filters;
  }

  get to(): Date {
    return this._filters.to;
  }

  set from_string(s: string) {
    this._filters.from = new Date(s);
    this.queryParamsService.globalFilterParams = this.filters;
  }
  get from_string(): string {
    return this._filters.from.toISOString();
  }

  set to_string(s: string) {
    this._filters.to = new Date(s);
    this.queryParamsService.globalFilterParams = this.filters;
  }
  get to_string(): string {
    return this._filters.to.toISOString();
  }

  set onlyActive(active: boolean) {
    this._filters.onlyActive = active || null;
    this.queryParamsService.globalFilterParams = this.filters;
  }
  get onlyActive(): boolean {
    return this._filters.onlyActive;
  }

  reset() {
    this.filters = {
      from: new Date().toISOString(),
      to: new Date().toISOString(),
      network: null,
      networkName: null,
      query: null,
      onlyActive: true,
    };
  }

  private initFilters() {
    const params = this.queryParamsService.globalFilterParams;

    const from = params.from || getDefaultFrom().toISOString();
    const to = params.to || getDefaultTo().toISOString();
    const network = params.network || null;
    const networkName = params.networkName || null;
    const query = params.query || null;
    const onlyActive = params.onlyActive;

    this.filters = { from, to, network, networkName, query, onlyActive };
  }

  constructor(
    public dateFormatPipe: DateFormatPipe,
    private queryParamsService: QueryParamsService,
    router: Router
  ) {
    router.events.subscribe((event) => {
      if (event instanceof NavigationStart && !event.url.includes('/dashboard-ng')) {
        this.initFilters();
      }
    });

    this.initFilters();
  }
}
