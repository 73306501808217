import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';

@Injectable()
export class ExportService {
  private stream: Subscription;
  private data: any[];
  private fields: { label: string; key: string; modifier?: Function }[];
  private name: string;

  constructor() {}

  public export(
    data: any[],
    fields: { label: string; key: string; modifier?: Function }[],
    filename: string
  ): void {
    const file = filename || 'export';

    const csvData = '\ufeff' + this.generateCsv(data, fields);
    const blob = new Blob([csvData], { type: 'text/csv' });
    const fileName = `${file}.csv`;

    if (navigator.msSaveBlob) window.navigator.msSaveBlob(blob, fileName);
    else {
      const a = document.createElement('a'),
        url = window.URL.createObjectURL(blob);

      a.setAttribute('style', 'display: none;');
      document.body.appendChild(a);
      a.href = url;
      a.download = fileName;
      a.click();
    }
  }

  public generateCsv(
    datas: any[],
    fields: { label: string; key: string; modifier?: Function }[]
  ): string {
    const rows = [];
    let cols = [];

    fields.forEach((field) => {
      cols.push(`"${field.label}"`);
    });
    rows.push(cols.join(';'));

    datas.forEach((data) => {
      cols = [];
      fields.forEach((field) => {
        let val = '';

        try {
          val = field.key.split('.').reduce((a, c) => a[c], data);
          if (val === null) val = '';
        } catch (e) {
          if (!(e instanceof TypeError)) throw e;
        }

        if (field.modifier) val = field.modifier(val);
        cols.push(`"${val}"`);
      });
      rows.push(cols.join(';'));
    });

    return rows.join('\r\n');
  }
}
