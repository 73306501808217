import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Customer } from '@app/modules/customers/models/customer';
import { CustomersService } from '@app/modules/customers/services/customers.service';
import { heroicons } from '@assets/icons/heroicons';
import { debounceTime, firstValueFrom } from 'rxjs';

export interface DialogData {}

@Component({
  selector: 'tu-search-modal',
  templateUrl: './search-modal.component.html',
})
export class SearchModalComponent implements OnInit {
  constructor(
    public router: Router,
    public modalRef: MatDialogRef<SearchModalComponent>,
    public customersService: CustomersService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  public searchForm = this.fb.group({
    search: [null],
  });

  public loading: boolean = false;
  public heroicons = heroicons;
  public displayedResult: Customer[] = [];
  public searchedTerm = '';
  public isLoading = false;

  public ngOnInit() {
    this.searchForm.controls.search.valueChanges
      .pipe(debounceTime(window['__CONFIG__']?.features?.globalSearchPreview ? 600 : 0))
      .subscribe(async (value) => {
        this.searchedTerm = value;
        if (value.length < 3) return;

        if (!window['__CONFIG__']?.features?.globalSearchPreview) {
          return;
        }

        this.isLoading = true;
        const customers = await firstValueFrom(this.customersService.searchCustomer(value));

        this.isLoading = false;
        return (this.displayedResult = customers.slice(0, 5));
      });
  }

  public async onSubmitSearchCustomers() {
    this.router.navigate(['/customers/search', { term: this.searchedTerm }]);

    this.closeModal();
  }

  public closeModal(): void {
    this.modalRef.close();
  }
}
