import { Injectable } from '@angular/core';
import { MaintenanceStatus, Source, Option, FetchResponse, Filter, Level } from '../models/upKeep';
import { environment as env } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class UpKeepService {
  private _sources: Source[];

  public options: Option = {
    timeout: 1000,
    punishTime: 60000,
  };

  constructor() {
    this._sources = UpKeepService.prepareSources(env.config.maintenanceUrlList);
  }

  public async getMaintenanceAlert(): Promise<MaintenanceStatus | null> {
    const response = await this.fetch({
      channel: 'bo/global',
      displayed: true,
    });

    if (response) {
      const alert = response.payload
        .filter((maintenanceAlert) => {
          // Fitre only on alerts whose date range is correct

          let hasDisplayRangeTime = false;
          const now = new Date();

          if (!maintenanceAlert.displayBefore && !maintenanceAlert.displayAfter) {
            hasDisplayRangeTime = true;
          } else if (maintenanceAlert.displayAfter && !maintenanceAlert.displayBefore) {
            const displayAfterDate = new Date(maintenanceAlert.displayAfter);

            hasDisplayRangeTime = displayAfterDate >= now;
          } else if (maintenanceAlert.displayBefore && maintenanceAlert.displayAfter) {
            const displayAfterDate = new Date(maintenanceAlert.displayAfter);
            const displayBeforeDate = new Date(maintenanceAlert.displayBefore);

            hasDisplayRangeTime = now >= displayAfterDate && now <= displayBeforeDate;
          }

          return maintenanceAlert.level === Level.CRITICAL && hasDisplayRangeTime;
        })
        .sort((prevAlert, nextAlert) => {
          // Sort of the end of maintenance on the nearest date

          if (!nextAlert.displayBefore) {
            return 1;
          }

          if (!prevAlert.displayBefore) {
            return -1;
          }

          if (prevAlert.displayBefore && nextAlert.displayBefore) {
            const prevDisplayBefore = new Date(prevAlert.displayBefore);
            const nextDisplayBefore = new Date(nextAlert.displayBefore);

            if (nextDisplayBefore < prevDisplayBefore) {
              return -1;
            }
          }

          return 0;
        })
        .shift();

      return alert ?? null;
    }

    return null;
  }

  private async fetch(filters: Filter): Promise<FetchResponse | null> {
    const startDate = new Date();
    const startTime = startDate.getTime();

    for (const source of this._sources) {
      if (source.punishedUntil && source.punishedUntil > startDate) {
        continue;
      }

      try {
        const sourceRequestUrl = new URL(source.url);

        for (const [key, filter] of Object.entries(filters)) {
          sourceRequestUrl.searchParams.append(key, filter);
        }

        const sourceRequestUrlString = sourceRequestUrl.toString();

        const response = await UpKeepService.manageFetch(sourceRequestUrlString, {
          timeout: this.options.timeout ?? 1000,
        });

        const isCached = Boolean(source.cachedUntil && source.cachedUntil > startDate);

        if (!isCached) {
          const headerCacheControlMaxAge =
            UpKeepService.maybeExtractCacheControlMaxAgeFrom(response);

          if (headerCacheControlMaxAge !== null) {
            const cachedUntil = UpKeepService.makeDatePlusMilliseconds(
              headerCacheControlMaxAge * 1000
            );
            source.cachedUntil = cachedUntil;
          }
        }

        const responseBody: MaintenanceStatus[] = await response.json();

        const successTime = Date.now();

        return {
          isCached,
          time: successTime - startTime,
          payload: responseBody,
          source,
        };
      } catch (e) {
        const punishTime = this.options.punishTime ?? 10000;
        const punishedUntil = UpKeepService.makeDatePlusMilliseconds(punishTime);

        const sourceIndex = this.getSourceArrayIndex(source.index);

        if (sourceIndex > -1) {
          this._sources[sourceIndex].punishedUntil = punishedUntil;
        }

        continue;
      }
    }

    return null;
  }

  private getSourceArrayIndex(index: string): number {
    return this._sources.findIndex((source) => source.index === index);
  }

  private static maybeExtractCacheControlMaxAgeFrom(response: Response): number | null {
    const headerCacheControl = response.headers.get('cache-control');
    const headerCacheControlMaxAgeMatch = /max-age=(?<seconds>\d+)/gi.exec(
      headerCacheControl ?? ''
    );

    if (!headerCacheControlMaxAgeMatch) return null;

    return headerCacheControlMaxAgeMatch.groups?.seconds
      ? +headerCacheControlMaxAgeMatch.groups?.seconds
      : null;
  }

  private static prepareSources(urls: string[]): Source[] {
    const sources: Source[] = [];

    for (const index in urls) {
      const url = urls[index];
      sources.push({
        url,
        index,
        punishedUntil: null,
        cachedUntil: null,
      });
    }

    return sources;
  }

  private static async manageFetch(resource: string, options: Option = {}): Promise<Response> {
    return UpKeepService.fetchWithTimeout(resource, options);
  }

  private static async fetchWithTimeout(resource: string, options: Option = {}): Promise<Response> {
    const { timeout = 8000 } = options;

    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeout);

    const response = await fetch(resource, {
      ...options,
      signal: controller.signal,
    });
    clearTimeout(id);

    return response;
  }

  private static fetchWithoutTimeout(resource: string): Promise<Response> {
    return fetch(resource);
  }

  private static makeDatePlusMilliseconds(milliseconds = 0, date = new Date()) {
    date.setMilliseconds(date.getMilliseconds() + milliseconds);

    return date;
  }
}
