import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AdminlabsComponent } from '@app/models/adminlabs';
import { Observable, map } from 'rxjs';
import { environment as env } from '@env/environment';
import { AuthService } from '@app/modules/shared/services/auth.service';

@Injectable()
export class AdminlabsService {
  private http: HttpClient;

  constructor(handle: HttpBackend, private authService: AuthService) {
    this.http = new HttpClient(handle);
  }

  public getComponentList(componentIdList: string[]): Observable<AdminlabsComponent[]> {
    return this.http
      .get<AdminlabsComponent[]>(`${env.config.adminlabsURL}/getComponents`, {
        headers: {
          authorization: `Bearer ${this.authService.token}`,
        },
      })
      .pipe(
        map((componentList) => {
          return componentList.filter(({ id }) => componentIdList.includes(id));
        })
      );
  }
}
