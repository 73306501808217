import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { PermissionGuard } from '../guards/permission.guard';

@Directive({
  selector: '[hasOnePermission], [hasAllPermission]',
})
export class HasPermissionDirective implements OnInit {
  @Input('hasOnePermission') onePer: Array<string>;
  @Input('hasAllPermission') allPer: Array<string>;

  constructor(public permissionGuard: PermissionGuard, private element: ElementRef) {}

  ngOnInit() {
    if (this.notEmpty(this.onePer)) {
      let allowed = this.permissionGuard.isOnePermissionAllowed(this.onePer);
      if (!allowed) this.element.nativeElement.remove();
    }

    if (this.notEmpty(this.allPer)) {
      let allowed = this.permissionGuard.isAllPermissionAllowed(this.allPer);
      if (!allowed) this.element.nativeElement.remove();
    }
  }

  private notEmpty(value: any): boolean {
    if (Array.isArray(value)) return value.length > 0;
    return !!value;
  }
}
