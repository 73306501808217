import { Injectable } from '@angular/core';

@Injectable()
export class PaginationService {
  private _selectedPage: { [route: string]: number } = {};

  setSelectedPage(selectedPage: number, id = location.pathname) {
    this._selectedPage[id] = selectedPage;
  }
  getSelectedPage(id = location.pathname): number {
    return this._selectedPage[id] || 1;
  }
}
