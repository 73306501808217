import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router } from '@angular/router';

@Injectable()
export class ConfigGuard implements CanActivate, CanActivateChild {
  constructor(private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot) {
    const features = route.data.features as string[];

    const canActivate = features.every((feature) => {
      return Boolean(window['__CONFIG__']?.features?.[feature]);
    });

    if (!canActivate) {
      this.router.navigate(['/403']);

      return canActivate;
    }

    return canActivate;
  }

  canActivateChild(route: ActivatedRouteSnapshot) {
    const features = route.data.features as string[];

    const canActivateChild = features.every((feature) => {
      return Boolean(window['__CONFIG__']?.features?.[feature]);
    });

    if (!canActivateChild) {
      this.router.navigate(['/403']);

      return canActivateChild;
    }

    return canActivateChild;
  }
}
