<form
  [formGroup]="searchForm"
  class="min-h-screen tw-flex tw-flex-col tw-space-y-2"
  (ngSubmit)="onSubmitSearchCustomers()"
>
  <div
    class="tw-flex tw-justify-between tw-rounded tw-border-2 tw-py-1 tw-pl-3 tw-pr-1 tw-text-gray-400 focus:tw-border-gray-700 focus:tw-text-gray-700"
  >
    <input
      formControlName="search"
      id="q"
      name="q"
      type="search"
      class="tw-flex-grow tw-outline-none focus:tw-text-gray-700"
      placeholder="{{ 'pages.header.search_client' | translate }}"
      autocomplete="off"
      role="search"
    />
    <button
      type="submit"
      class="tw-flex tw-h-12 tw-w-12 tw-items-center tw-justify-center tw-rounded-full tw-text-gray-100"
      [class]="searchedTerm ? 'tw-bg-gray-400' : 'tw-bg-gray-200 tw-cursor-not-allowed'"
      [disabled]="!searchedTerm"
      [ngbTooltip]="
        ('pages.header.label_search' | translate) +
        ' ' +
        searchedTerm +
        ' ' +
        ('pages.header.label_everywhere' | translate)
      "
    >
      <span class="tw-sr-only">
        {{ 'pages.header.label_search' | translate }}
        {{ searchedTerm }}
        {{ 'pages.header.label_everywhere' | translate }}
      </span>
      <tu-hero-icon
        *ngIf="!isLoading; else loader"
        [path]="heroicons.outline.search"
        klass="tw-h-6 tw-w-6"
      ></tu-hero-icon>
    </button>
    <button type="submit"></button>
  </div>

  <ul
    *ngIf="displayedResult.length"
    class="tw-m-0 tw-flex tw-flex-col tw-divide-y tw-divide-gray-100"
  >
    <li>
      <a
        *ngFor="let result of displayedResult"
        [routerLink]="['/customers', result.customer_id]"
        class="tw-flex tw-cursor-pointer tw-items-center tw-rounded tw-py-1 tw-px-3 tw-text-current hover:tw-bg-gray-100 hover:tw-no-underline focus:tw-no-underline"
      >
        <img
          class="tw-h-10 tw-w-10 tw-rounded-full"
          default="/assets/img/user.svg"
          [src]="result.picture"
          alt="Photo de profil de l'utilisateur"
        />

        <div class="tw-flex tw-w-full tw-items-center tw-justify-between">
          <div class="tw-mx-2">
            <span>{{ result.firstname + ' ' + result.lastname }}</span>
            <div
              *ngIf="result.medias.length"
              class="tw-mt-1 tw-w-full tw-truncate tw-text-xs tw-font-normal tw-normal-case tw-text-gray-500"
            >
              <span>
                ({{ result.medias[0].code }}{{ result.medias.length === 1 ? '' : '...' }})
              </span>
            </div>
          </div>
          <tu-hero-icon
            *ngIf="result?.provider !== 'airweb'"
            [path]="heroicons.outline.userGroup"
            klass="tw-h-6 tw-w-6"
          ></tu-hero-icon>
        </div>
      </a>
    </li>
  </ul>
</form>

<ng-template #loader>
  <svg class="tw-mx-auto tw-h-10 tw-w-10 tw-animate-spin tw-fill-current" viewBox="0 0 24 24">
    <path d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
  </svg>
</ng-template>
