import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { LinesService } from '../services/lines.service';

@Injectable()
export class LinesResolver implements Resolve<any[]> {
  constructor(private lines: LinesService) {}

  resolve(): Observable<any[]> {
    return this.lines.getLines();
  }
}
