import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable()
export class PermissionGuard implements CanActivate, CanActivateChild {
  constructor(private router: Router, private authService: AuthService) {}

  canActivate() {
    return true;
  }

  canActivateChild(route: ActivatedRouteSnapshot) {
    let data = route.data.permissions as {
      One?: Array<string>;
      All?: Array<string>;
      superAdmin?: boolean;
      redirectTo: string;
    };
    if (typeof data === 'undefined') return true;

    if (typeof data.One !== 'undefined') {
      if (this.isOnePermissionAllowed(data.One)) return true;
    } else if (typeof data.All !== 'undefined') {
      if (this.isAllPermissionAllowed(data.All)) return true;
    } else if (data?.superAdmin === true) {
      if (this.authService.isSuperAdmin) return true;
    }

    if (data.redirectTo && data.redirectTo !== undefined) this.router.navigate([data.redirectTo]);

    return false;
  }

  isOnePermissionAllowed(permissions: Array<string>): boolean {
    if (!Array.isArray(permissions)) throw 'permissions must be an array !';
    if (permissions) {
      return permissions.some((p) => this.authService.permissions.indexOf(p) >= 0);
    }
    return false;
  }

  isAllPermissionAllowed(permissions: Array<string>): boolean {
    if (!Array.isArray(permissions)) throw 'permissions must be an array !';
    if (permissions) {
      const isValid = permissions.every((p) => this.authService.permissions.indexOf(p) >= 0);

      return isValid;
    }
    return false;
  }
}
