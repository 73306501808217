import { Injectable } from '@angular/core';

@Injectable()
export class ToDateStringService {
  constructor() {}

  public toDateString(date: Date): string {
    let day = `0${date.getDate()}`.slice(-2),
      month = `0${date.getMonth() + 1}`.slice(-2),
      year = date.getFullYear().toString();

    return `${year}-${month}-${day}`;
  }
}
