import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthGuard implements CanActivate, CanLoad {
  constructor(private authService: AuthService, private router: Router) {}

  async canActivate(route) {
    // If we have a token on the URL, we try to login with the token
    if (route.queryParams.token) {
      this.authService.tokenLoader = true;
      await this.authService.loginWithToken(route.queryParams.token);

      this.authService.tokenLoader = false;
      // Redirect on the same url without token
      return this.router.navigate([route._routerState.url], {
        queryParams: {
          token: null,
        },
        queryParamsHandling: 'merge',
      });
    }

    return this.authService.checkLogin();
  }

  async canLoad() {
    const currentRoute = this.router.getCurrentNavigation().extractedUrl;

    // If we have a token on the URL, we try to login with the token
    if (currentRoute.queryParams.token) {
      this.authService.tokenLoader = true;
      await this.authService.loginWithToken(currentRoute.queryParams.token);

      let currentPath = [];

      if (
        currentRoute.root.children['primary'] &&
        currentRoute.root.children['primary'].segments.length
      ) {
        currentPath = currentRoute.root.children['primary'].segments.map(({ path }) => path);
      }

      this.authService.tokenLoader = false;
      // Redirect on the same url without token
      return this.router.navigate(currentPath, {
        queryParams: {
          ...currentRoute.queryParams,
          token: null,
        },
        queryParamsHandling: 'merge',
      });
    }

    return this.authService.checkLogin();
  }
}
