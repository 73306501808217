import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { GenericResponse } from '@app/modules/shared/models/generic-response';
import { AuthService } from '@app/modules/shared/services/auth.service';
import { FilterService } from '@app/modules/shared/services/filter.service';
import { environment as env } from '@env/environment';
import { NotificationsService } from 'angular2-notifications';
import { firstValueFrom, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Comment } from '../models/comment';
import { Order, OrderProcessStatus, OrderStatus, SchoolOrder } from '../models/order';

@Injectable({
  providedIn: 'root',
})
export class OrdersService {
  public order: Order;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private router: Router,
    private filters: FilterService,
    private notification: NotificationsService
  ) {}

  public getOrders(cid: () => number = () => null, otherFilter: any = null): Observable<Order[]> {
    return of([null]).pipe(
      switchMap(() =>
        this.http.get<any>(env.config.feedRoot + `Order/getOrder.json?cat_id=` + cid(), {
          params: otherFilter !== null ? otherFilter : this.filters.filtersWithID,
        })
      ),

      map(({ response: r }) => {
        return r.orders as Order[];
      })
    );
  }

  /**
   * @deprecated - This function should not be used anymore. Use `getOrderDetails` instead.
   */
  public getLegacyOrderDetails(orderId: string | number): Promise<Order> {
    const legacyOrder$ = this.http
      .get<GenericResponse<{ orders: Order[] }>>(`${env.config.feedRoot}Order/getOrder`, {
        params: { id: orderId },
      })
      .pipe(
        map(({ response }) => {
          if (Array.isArray(response.orders) && response.orders.length > 0) {
            return response.orders[0];
          }

          return null;
        })
      );

    return firstValueFrom(legacyOrder$);
  }

  public getOrderDetails(orderId: string | number): Promise<SchoolOrder> {
    const order$ = this.http
      .get<GenericResponse<SchoolOrder>>(`${env.config.feedRoot}Order/details`, {
        params: { id: orderId },
      })
      .pipe(
        map(({ response }) => {
          return response as SchoolOrder;
        })
      );

    return firstValueFrom(order$);
  }

  public getOrderInvoice(id: string, options: { legacy: boolean; version?: string }) {
    let endpoint = options.legacy ? 'InvoicePrinter/getInvoice' : 'Order/printInvoice';

    if (options?.version) {
      if (options.version === 'V1') {
        endpoint = 'invoicePrinter/getInvoice';
      }

      if (options.version === 'V2') {
        endpoint = 'Order/printInvoice';
      }

      if (options.version === 'V3') {
        endpoint = 'Order/printInvoiceV3';
      }
    }

    return this.http
      .post<Blob>(
        env.config.feedRoot + endpoint,
        {
          orderId: id,
        },
        {
          responseType: 'blob' as 'json',
        }
      )
      .pipe(
        map((blob) => {
          return new Blob([blob], { type: 'application/pdf' });
        })
      );
  }

  public updateArticleFieldValue(
    orderId: string,
    orderArticleId: string,
    orderItemId: string,
    fieldId: string,
    value: unknown
  ) {
    const body = {
      orderId,
      orderArticleId,
      orderItemId,
      fieldId,
      value,
    };

    return firstValueFrom(
      this.http
        .post<GenericResponse>(`${env.config.feedRoot}Order/updateArticleFieldValue.json`, body)
        .pipe(
          map(({ response }) => {
            return response;
          })
        )
    );
  }

  public updateStatus(order_id: number | string, status: string, isPayment = false) {
    return this.http
      .post<any>(env.config.feedRoot + 'Order/updateOrderStatus.json', {
        order_id,
        status,
        isPayment,
      })
      .pipe(
        map(({ response }) => {
          if (response.errorMessage) console.log({ response });

          return response;
        })
      );
  }

  public updateProcessStatus(order_id: number, process_status: string) {
    return this.http
      .post<any>(env.config.feedRoot + 'Order/updateOrderProcessStatus.json', {
        order_id,
        process_status,
      })
      .pipe(
        map(({ response }) => {
          if (response.errorMessage) console.log({ response });

          return response;
        })
      );
  }

  public getOrderComments(id: number, version: number) {
    return this.http
      .get<any>(env.config.feedRoot + `Order/getOrderComments.json?order_id=${id}&v=${version}`)
      .pipe(
        map(({ response }) => {
          if (response.errorMessage) console.log({ response });

          return response.comments as Comment[];
        })
      );
  }

  public postOrderComment(order_id: number, comment: string) {
    return this.http
      .post<any>(env.config.feedRoot + 'Order/postOrderComment.json', {
        order_id,
        comment,
      })
      .pipe(
        map(({ response }) => {
          if (response.errorMessage) console.log({ response });

          return response;
        })
      );
  }

  public editOrderComment(id: number, comment: string) {
    return this.http
      .post<any>(env.config.feedRoot + 'Order/editOrderComment.json', { id, comment })
      .pipe(
        map(({ response }) => {
          if (response.errorMessage) console.log({ response });

          return response;
        })
      );
  }

  public deleteOrderComment(id: number) {
    return this.http.post<any>(env.config.feedRoot + 'Order/deleteOrderComment.json', { id }).pipe(
      map(({ response: r }) => {
        return r;
      })
    );
  }

  public updateNbOrderToProcess(): Promise<number> {
    return firstValueFrom(
      this.http
        .get<GenericResponse<{ count: number }>>(`${env.config.feedRoot}Order/count`, {
          params: {
            max: 11,
            status: OrderStatus.completed,
            processingStatus: `${OrderProcessStatus.to_process},${OrderProcessStatus.processing}`,
          },
        })
        .pipe(
          map((response) => {
            const toProcess = response?.response?.count || 0;
            return toProcess;
          })
        )
    );
  }

  /**
   * Start the checkout process of a given order manually
   * @param orderId - The order id to checkout
   * @param force - This force the checkout even if the order is already checked out. Throw otherwise.
   */
  public checkoutOrder(orderId: number | string, force: boolean = true): Promise<boolean> {
    const response$ = this.http
      .post<GenericResponse<{ success: boolean }>>(`${env.config.feedRoot}/order/checkout`, {
        orderId,
        force,
      })
      .pipe(
        map(({ response }) => {
          return response.success;
        })
      );

    return firstValueFrom(response$);
  }
}
