export interface Option {
  timeout?: number;
  punishTime?: number;
}

export interface Filter {
  channel: string;
  displayed: boolean;
}

export interface Source {
  url: string;
  index: string;
  punishedUntil: Date | null;
  cachedUntil: Date | null;
}

export interface FetchResponse {
  isCached: boolean;
  time: number;
  payload: MaintenanceStatus[];
  source: Source;
}

export interface MaintenanceStatus {
  level: Level;
  channel: string;
  displayAfter?: string; // Min Date
  displayBefore?: string; // Max Date
  flags?: string[];
}

export enum Level {
  CRITICAL = 'CRITICAL',
  WARNING = 'WARNING',
  NOTICE = 'NOTICE',
}
