import { Component, Input } from '@angular/core';

@Component({
  selector: 'tu-loader',
  template: `
    <div class="tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center">
      <svg [class]="iconClass" viewBox="0 0 24 24">
        <path d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
      </svg>
    </div>
  `,
})
export class LoaderComponent {
  @Input('klass')
  public klass: string = '';

  get iconClass(): string {
    let classList = ['tw-mx-auto', 'tw-animate-spin', 'tw-fill-current'];

    if (this.klass) {
      classList = classList.concat(this.klass.split(' '));
    } else {
      classList = classList.concat(['tw-h-10', 'tw-w-10']);
    }

    return classList.join(' ');
  }
}
