import { Component, OnDestroy, OnInit } from '@angular/core';
import { CacheBustingService } from '@app/modules/shared/services/cache-busting.service';
import { AuthService } from './modules/shared/services/auth.service';

@Component({
  selector: 'tu-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
  public title = 'tu works!';
  constructor(private readonly cacheBuster: CacheBustingService, public auth: AuthService) {}

  ngOnInit() {
    if (process.env.NODE_ENV === 'development') return;
    // 5 minutes
    this.cacheBuster.initVersionCheck(1000 * 60 * 5);
  }

  ngOnDestroy() {
    this.cacheBuster.clearVersionCheck();
  }
}
