import { Address } from '@app/modules/shared/models/address';
import { Filters } from '@app/modules/shared/models/filters';
import { Attribute, Customer } from '../../customers/models/customer';
import { Item } from './item';

export interface Order {
  id: string;
  order_identifier: string;
  network_id: string;
  network_logo: string;
  status: OrderStatus;
  process_status: string;
  total_base: string;
  total_vat: string;
  date: string;
  email_sent: string;
  sent_at: string;
  user: Customer;
  items: Item[];
  vats: Array<{ vat: string; price: number }>;
  installments: string;
  inst_schedule?: any[];
  shipping_mode: string;
  shipping_address?: Address;
  origin_type?: string;
  origin_agent?: string;
  hasVoucher?: boolean;
  voucher?: string;
  universal?: boolean;
  payment_provider?: string;
  mandate_id?: string;
  issuer?: 'airweb' | 'sncf';
  item_subscription_count?: string;
  client: {
    id: string;
    name: string;
    slug: string;
  } | null;
  discounts: OrderDiscount[];
}

export interface SchoolOrder {
  id: number;
  code: string;
  date: string;
  status: OrderStatus;
  processStatus: OrderProcessStatus | null;
  taxFreeAmount: string;
  totalAmount: string;
  installments: string;
  paymentMethod: OrderPaymentMethod;
  paymentProvider: string;
  voucher: string;
  networkId: number;
  originType: OrderOriginType;
  customer?: SchoolOrderCustomer | null;
  articles: SchoolOrderArticle[];
  shippingAddress?: OrderShippingAddress | null;
  discounts: OrderDiscount[];
  checkouts: Checkout[];
}

export interface Checkout {
  executionStatus: string;
  executionDuration: number;
  initiatorType: string;
  initiatorAgent: string;
  createdAt: string;
}

export interface SchoolOrderArticle {
  id: number;
  unitPrice: string;
  taxFreeUnitPrice: string;
  submissionId?: number;
  customerLastSubmissionId: number;
  customerLastSubmissionStatus: string;
  product: {
    id: number;
    name: string;
    toProcess: boolean;
  };
  customer?: SchoolOrderCustomer | null;
  fields?: SchoolComplementaryField[];
  trip?: Trip | null;
  reservation?: ArticleBooking | null;
}

export interface Trip {
  origin: {
    label: string;
    url: string;
  };
  destination: {
    label: string;
    url: string;
  };
}

export interface SchoolComplementaryField {
  fieldId: number;
  valueId?: any;
  valueLabel?: any;
  value: string;
}

export enum OrderStatus {
  completed = 'COMPLETED',
  pending = 'PENDING',
  canceled = 'CANCELED',
  error = 'ERROR',
  regularization = 'REGULARIZATION',
  preauthorized = 'PREAUTHORIZED',
  waiting_for_payment = 'WAITING_FOR_PAYMENT',
  to_refund = 'TO_REFUND',
  refunded = 'REFUNDED',
  defaultPayment = 'PAYMENT_ISSUE',
}

export enum OrderProcessStatus {
  processed = 'PROCESSED',
  processing = 'PROCESSING',
  to_process = 'TO_PROCESS',
  autoprocessed = 'AUTOPROCESSED',
  incomplete = 'INCOMPLETE',
}

export enum OrderPaymentMethod {
  CARD = 'CARD',
  TRANSFER = 'TRANSFER',
  DEPOSIT = 'DEPOSIT',
  CASH = 'CASH',
}

export enum OrderOriginType {
  APP = 'APP',
  ESHOP = 'ESHOP',
  SCHOOL = 'SCHOOL',
  RETAIL = 'RETAIL',
}

export interface OrderFilters extends Filters {
  status?: string;
  processingStatus?: string;
  supports?: string;
  installments?: number;
  total?: number;
  provider?: string;
  paymentMethod?: string;
  waiting?: boolean;
  cat_id?: number;
  originType?: string;
  attribute?: number;
  attributeSearch?: string;
  productId?: string;
}

export interface SchoolOrderCustomer {
  id: number;
  firstname: string | null;
  lastname: string | null;
  birthdate?: string | null;
  email?: string | null;
  phone?: string | null;
  avatar?: string | null;
  address?: Address | null;
  attributes?: Attribute[] | null;
}

export interface OrderShippingAddress {
  streetNumber: string;
  route: string;
  city: string;
  zipCode: string;
  country: string;
}

export interface OrderShippingAddress {
  streetNumber: string;
  route: string;
  city: string;
  zipCode: string;
  country: string;
}

export interface OrderDiscount {
  id: number;
  sourceType: string | null;
  sourceCode: string | null;
  type: OrderDiscountType;
  value: string | null;
}

export enum OrderDiscountType {
  AMOUNT = 'AMOUNT',
  RATIO = 'RATIO',
  ARTICLE_AMOUNT = 'ARTICLE_AMOUNT',
  ARTICLE_RATIO = 'ARTICLE_RATIO',
  FREE = 'FREE',
  GIFT = 'GIFT',
}

export interface ArticleBooking {
  type: BookingTrip;
  message: string | null;
  tripSeatCount: string;
  tripDepartureDate: string;
  tripDepartureLocationLabel: string;
  tripDepartureLocationRef: string;
  tripArrivalDate: string;
  tripArrivalLocationLabel: string;
  tripArrivalLocationRef: string;
}

export enum BookingTrip {
  TRIP = 'TRIP',
}