import { Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { ModalService } from '../../services/modal.service';

@Component({
  selector: 'tu-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent {
  @ViewChild('answer', { static: true }) protected answersEl: ElementRef;
  public opened = false;

  public currValue: string;

  public popup: {
    title: string;
    content: string;
    input?: InputOptions;
    options: Option[];
  };

  constructor(private renderer: Renderer2, private modalService: ModalService) {
    this.modalService.registerComponent(this);
  }

  public async open(title: string, content: string, options: Option[]): Promise<string> {
    this.popup = { title, content, options };
    this.opened = true;

    const p = new Promise<string>((resolve, reject) => {
      this.renderer.listen(this.answersEl.nativeElement, 'click', (event) => {
        if (!event.target.value) return;
        const res = event.target.value;

        if (this.popup.options.find((o) => o.value === res)) resolve(res);
        else reject(new Error('Invalid choice'));

        this.opened = false;
      });
    });
    return await p;
  }

  public async prompt(
    title: string,
    content: string,
    input: InputOptions,
    options: Option[]
  ): Promise<{ choice: string; value: string }> {
    this.currValue = input.value || '';
    this.popup = { title, content, input, options };
    this.opened = true;

    const p = new Promise<{ choice: string; value: string }>((resolve, reject) => {
      this.renderer.listen(this.answersEl.nativeElement, 'click', (event) => {
        if (!event.target.value) return;
        const choice = event.target.value;

        if (this.popup.options.find((o) => o.value === choice))
          resolve({ choice, value: this.currValue });
        else reject(new Error('Invalid choice'));

        this.opened = false;
      });
    });
    return await p;
  }
}

export interface Option {
  label: string;
  value: string;
}

export interface InputOptions {
  type?: 'text' | 'time' | 'date' | 'email' | 'image';
  placeholder?: string;
  value?: string;
  src?: string;
}
