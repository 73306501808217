import { Injectable } from '@angular/core';
import { InputOptions, ModalComponent, Option } from '../components/modal/modal.component';

@Injectable()
export class ModalService {
  private modalComponent: ModalComponent;

  public registerComponent(modalComponent: ModalComponent) {
    this.modalComponent = modalComponent;
  }

  public open(title: string, content: string, options: Option[]): Promise<string> {
    return this.modalComponent.open(title, content, options);
  }

  public prompt(
    title: string,
    content: string,
    input: InputOptions,
    options: Option[]
  ): Promise<{ choice: string; value: string }> {
    return this.modalComponent.prompt(title, content, input, options);
  }
}
