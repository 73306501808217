import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PaginationConfig } from '../models/server-pagination';

interface GlobalFilterParams {
  from: string;
  to: string;
  network: string;
  networkName: string;
  query: string;
  onlyActive: boolean;
}
interface QueryParams extends GlobalFilterParams, PaginationConfig, Record<string, any> {}

@Injectable()
export class QueryParamsService {
  public queryParams: Partial<QueryParams>;

  public set globalFilterParams(params: Partial<GlobalFilterParams>) {
    this.queryParams = { ...this.queryParams, ...params };

    this.setQueryParams(params);
  }

  public get globalFilterParams() {
    const { from, to, network, networkName, query } = this.queryParams;

    return { from, to, network, networkName, query };
  }

  public set localFilterParams(params: Record<string, any>) {
    this.queryParams = { ...this.globalFilterParams, ...this.paginationParams, ...params };

    this.setQueryParams(params);
  }

  public get localFilterParams() {
    //TODO : specify on local filter
    return this.queryParams;
  }

  public set paginationParams(params: Partial<PaginationConfig>) {
    this.queryParams = { ...this.queryParams, ...params };

    this.setQueryParams(params);
  }

  public get paginationParams() {
    const { offset, limit } = this.queryParams;
    return { offset, limit };
  }

  constructor(private route: ActivatedRoute) {
    const url = new URL(location.href);
    this.queryParams = Object.fromEntries(url.searchParams.entries());
  }

  public getInitialQueryParams = this.route.queryParams;

  public setQueryParams(params: Record<string, any>) {
    const url = new URL(location.href);
    const acceptedValues = [0, false];

    for (const [key, value] of Object.entries(params)) {
      if (value || acceptedValues.includes(value)) {
        url.searchParams.set(key, value);
      } else {
        url.searchParams.delete(key);
      }
    }

    history.replaceState(null, '', url.toString());
  }
}
