export function getDefaultFrom(): Date {
  const beforeYesterday = new Date();
  beforeYesterday.setHours(0);
  beforeYesterday.setMinutes(0);
  beforeYesterday.setSeconds(0);
  beforeYesterday.setHours(-24);

  return beforeYesterday;
}

export function getDefaultTo(): Date {
  const to = new Date();
  to.setHours(23);
  to.setMinutes(59);
  to.setSeconds(59);

  return to;
}
