import { Directive, ElementRef, HostBinding, Input } from '@angular/core';
import { from, Subject } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

@Directive({
  selector: 'img[default]',
})
export class DefaultImageDirective {
  private loadingSrc = '/assets/img/load.gif';
  private imgObs = new Subject<string>();

  @Input() default;

  @HostBinding('src') imgSrc: string;
  @Input('src') set src(src: string) {
    this.imgObs.next(src);
  }

  constructor(private el: ElementRef) {
    this.imgSrc = this.loadingSrc;

    this.imgObs
      .pipe(
        mergeMap((src) => from(this.loadImage(src))),
        map((img) => img || { src: this.default })
      )
      .subscribe((img) => (this.imgSrc = img.src));
  }

  private loadImage(src: string): Promise<HTMLImageElement> {
    if (!src) return Promise.resolve(null);

    return new Promise((resolve) => {
      const img = Object.assign(new Image(), {
        src,
        onload: () => resolve(img),
        onerror: () => resolve(null),
      });
    });
  }
}
