import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { NetworkService } from '@app/modules/network/services/network.service';
import { Observable } from 'rxjs';

@Injectable()
export class TagsMailNetworkResolver implements Resolve<any[]> {
  constructor(private networkService: NetworkService) {}

  resolve(): Observable<any[]> {
    return this.networkService.getTags();
  }
}
