import { Injectable } from '@angular/core';
import { environment as env } from '@env/environment';
import { ServerConfig } from '../models/server-pagination';
import { AuthService } from './auth.service';

@Injectable()
export class ServerPaginationService {
  constructor(private authService: AuthService) {}

  public get fetchConfig(): RequestInit {
    return {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${this.authService.token}`,
      },
    };
  }

  public serverConfig(path: string, data?: string) {
    const config: ServerConfig = {
      url: env.config.feedRoot + path,
      data: (res) => (data ? res.response?.[data] ?? [] : res.response),
      sortableKeys: (res) => res.headers.get('X-Airweb-Sortable-Keys')?.split(',') ?? [],
      limit: (res) => +res.headers.get('X-Airweb-Pagination-Limit'),
      count: (res) => +res.headers.get('X-Airweb-Pagination-Count'),
      offset: (res) => +res.headers.get('X-Airweb-Pagination-Offset'),
      pages: (res) => +res.headers.get('X-Airweb-Pagination-Pages'),
    };
    return config;
  }
}
