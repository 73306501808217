import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { SubmissionService } from '../../submissions/services/submission.service';
import { OrdersService } from '@app/modules/orders/services/orders.service';

@Injectable()
export class BadgeService {

  public orderToProcess = new Subject<number>();

  constructor(private submissionService: SubmissionService, private orderService: OrdersService) {}

  /*private _badge: { [menu: string]: number } = {};

	set badge(total: number) { this._badge[window.location.pathname] = total; }
	get badge(): number { return this._badge[window.location.pathname]; }*/

  public getUnsubmittedDocument(): Observable<any> {
    return this.submissionService.getUnsubmitted();
  }

  public async updateNbOrderToProcess() {
    const toProcess = await this.orderService.updateNbOrderToProcess();
    this.orderToProcess.next(toProcess);
  }
}
